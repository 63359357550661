import { NEXTJS_REVALIDATE_PAGE_IN_SECONDS } from 'siteConfig';
import { GetStaticProps } from 'next'
import { GlobalContentContextType, useGlobalContentContext } from 'context/GlobalContentContext';
import { MetaDataType } from 'components/metaData/MetaData';
import { Grid } from 'components/grid/Grid';
import { getGlobalContentContext } from 'drf/getGlobalContentContext'

import { useRouter } from 'next/router';

import { getPrivateAPI, getPublicAPI } from 'utils/helpers';

import style from './index404.module.scss';

type Props = {
  meta_data: MetaDataType;
  globalContentContextData: GlobalContentContextType;
};

const PageNotFound = (props: Props) => {
  const { setData } = useGlobalContentContext();
  setData(props.globalContentContextData)
  const router = useRouter();

  // const onClick = () => {
  //   router.push('/');
  // };
  // useEffect(() => {
  //   setData(props.generic.siteMetaData);
  // }, []);

  return (
    <article className="pageNotFound__container">
      <Grid />
      <h1 className="pageNotFound__title">
        404
      </h1>
    </article>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { preview, params } = context
  // const globalContentContextRequest = await fetch(`${getPublicAPI()}/globalcontentcontext`);
  // const globalContentContextData = await globalContentContextRequest.json();
  const globalContentContextData = await getGlobalContentContext(preview)

  const props: Props = {
    meta_data: Object.assign({}, globalContentContextData.site_meta_data),
    globalContentContextData: globalContentContextData
  };
  return {
    props,
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS,
  };
}

export default PageNotFound;
